import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { customStyles } from "./index.styles";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import InsertEmoticonOutlinedIcon from "@mui/icons-material/InsertEmoticonOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import { colorPalette } from "../../../shared/utils/constants";

interface Props {
  id: string;
}

const imageURL = `${process.env.PUBLIC_URL}/images/aboutUs2.png`;

const tags = (
  <Box width="100%" display="flex" justifyContent="center">
    <Grid container spacing={1} justifyContent="center">
      <Grid xs={3} item sx={customStyles.iconGrid}>
        <Box sx={customStyles.iconBox}>
          <StarBorderOutlinedIcon sx={customStyles.icon} />

          <Typography sx={customStyles.iconText}>Satisfactory Work</Typography>
        </Box>
      </Grid>

      <Grid xs={3} item sx={customStyles.iconGrid}>
        <Box sx={customStyles.iconBox}>
          <InsertEmoticonOutlinedIcon sx={customStyles.icon} />

          <Typography sx={customStyles.iconText}>Happy Customers</Typography>
        </Box>
      </Grid>

      <Grid xs={3} item sx={customStyles.iconGrid}>
        <Box sx={customStyles.iconBox}>
          <GroupsOutlinedIcon sx={customStyles.icon} />

          <Typography sx={customStyles.iconText}>Professional Team</Typography>
        </Box>
      </Grid>

      <Grid xs={3} item sx={customStyles.iconGrid}>
        <Box sx={customStyles.iconBox}>
          <AssignmentOutlinedIcon sx={customStyles.icon} />

          <Typography sx={customStyles.iconText}>Flexible contracts</Typography>
        </Box>
      </Grid>
    </Grid>
  </Box>
);

const AboutUs = ({ id }: Props) => (
  <Box
    id={id}
    sx={{ background: colorPalette.sectionTitle, my: 4, pt: 2, pb: 8 }}
  >
    <Container maxWidth="xl">
      <Box id={id} mt={6} mb={4} sx={{ xs: 0, md: 8 }}>
        <Typography variant="h3" sx={customStyles.text}>
          About us
        </Typography>
      </Box>

      <Container maxWidth="xl" sx={customStyles.card}>
        <Stack
          spacing={8}
          direction={{ xs: "column", sm: "row" }}
          sx={{ alignItems: "center" }}
        >
          <Box
            sx={[customStyles.mainBox, { backgroundImage: `url(${imageURL})` }]}
          />

          <Stack spacing={2}>
            <Typography color="white" fontSize={18}>
              Welcome to Radiant Property Management, where cleanliness meets
              convenience. We are a dedicated team of professionals committed to
              delivering top-notch cleaning services tailored to meet your
              needs. With years of experience in the industry, we understand the
              importance of a clean and healthy environment your businesses.
            </Typography>

            <Typography color="white" fontSize={18}>
              We specialise in professional commercial cleaning and property
              services in Melbourne, offering customized services for a wide
              range of businesses, including Offices, Childcare facilities,
              Hotels, Gyms, Retail stores, and all type of Commercial cleaning.
              With over a decade of experience in the industry, our focus on
              uncompromising quality, professionalism, and a business-friendly
              approach guarantees exceptional cleaning services and exceptional
              customer care for your commercial facility. Elevate your standards
              of cleanliness and service excellence with us.
            </Typography>

            <Typography color="white" fontSize={18}>
              At Radiant Property Management, we pride ourselves on our
              attention to detail and commitment to customer satisfaction. Our
              skilled cleaners undergo rigorous training and utilize the latest
              techniques to ensure exceptional results every time.
            </Typography>

            <Typography color="white" fontSize={18}>
              Choose Radiant Property Management for a spotless space and peace
              of mind. Let us handle the dirty work while you focus on what
              matters most. Contact us today to schedule your cleaning
              appointment!
            </Typography>

            {tags}
          </Stack>
        </Stack>
      </Container>

      <Container maxWidth="xl" sx={customStyles.textCard}>
        <Box
          sx={[customStyles.mainBox, { backgroundImage: `url(${imageURL})` }]}
        />

        <Stack spacing={2} mt={2}>
          <Typography textAlign="center" color="white">
            Welcome to Radiant Property Management, where cleanliness meets
            convenience. We are a dedicated team of professionals committed to
            delivering top-notch cleaning services tailored to meet your needs.
            With years of experience in the industry, we understand the
            importance of a clean and healthy environment for your businesses.
          </Typography>

          <Typography textAlign="center" color="white">
            We specialise in professional commercial cleaning and property
            services in Melbourne, offering customized services for a wide range
            of businesses, including offices, childcare facilities, hotels,
            gyms, retail stores, and all type of commercial cleaning. With over
            a decade of experience in the industry, our focus on uncompromising
            quality, professionalism, and a business-friendly approach
            guarantees exceptional cleaning services and exceptional customer
            care for your commercial facility. Elevate your standards of
            cleanliness and service excellence with us.
          </Typography>

          <Typography textAlign="center" color="white">
            At Radiant Property Management, we pride ourselves on our attention
            to detail and commitment to customer satisfaction. Our skilled
            cleaners undergo rigorous training and utilize the latest techniques
            to ensure exceptional results every time.
          </Typography>

          <Typography textAlign="center" color="white">
            Choose Radiant Property Management for a spotless space and peace of
            mind. Let us handle the dirty work while you focus on what matters
            most. Contact us today to schedule your cleaning appointment!
          </Typography>

          {tags}
        </Stack>
      </Container>
    </Container>
  </Box>
);

export default AboutUs;
