import { Box, Typography, Button, Stack, Grid, Container } from "@mui/material";
import { customStyles } from "./index.styles";
import { handleNavigation } from "../../../../shared/utils/helpers";

const HomeSectionText = () => (
  <Container maxWidth="xl">
    <Stack sx={customStyles.mainBox}>
      <Stack direction="column" pl={{ xs: 0, md: 8 }}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography sx={customStyles.text}>
              Radiant Property Management
            </Typography>
          </Grid>
        </Grid>

        <Grid item>
          <Typography sx={customStyles.description}>
            We are trusted experts in providing commercial and specialized
            cleaning services. Our mission is to deliver top-tier care, ensuring
            your facility's needs are met with precision and excellence.
          </Typography>

          <Typography sx={customStyles.description}>
            Our promise is clear: we offer quality, value, and adaptability in
            all we do. With years of expertise, a streamlined approach
            solutions, and a team of skilled, reliable professionals, we're
            committed to enhancing your facility’s cleanliness and operational
            efficiency.
          </Typography>
        </Grid>
      </Stack>

      <Box sx={customStyles.buttonBox}>
        <Button
          onClick={() => handleNavigation("contact")}
          variant="contained"
          sx={customStyles.button}
        >
          Contact Us
        </Button>
      </Box>
    </Stack>
  </Container>
);

export default HomeSectionText;
