import { colorPalette } from "../../../../shared/utils/constants";

export const customStyles = {
  mainBox: {
    opacity: 1,
    position: "absolute",
    top: "40%",
    transform: "translate(5%, -30%)",
    justifyContent: "center",
    width: { xs: "90vw", sm: "70%", xl: "50%" },
    zIndex: 1,
  },
  text: {
    fontSize: {
      xs: 40,
      sm: 54,
      md: 55,
      lg: 60,
      xxl: 75,
    },
    display: "flex",
    fontFamily: "'Bebas Neue', sans-serif",
    flexWrap: "wrap",
    color: colorPalette.sectionTitle,
  },
  description: {
    mt: {
      xs: 0.5,
      sm: 4,
      md: 1,
    },
    fontSize: {
      xs: 16,
      sm: 18,
      lg: 20,
    },
    width: "90%",
    display: "flex",
    flexWrap: "wrap",
    color: colorPalette.sectionTitle,
  },
  buttonBox: {
    display: "flex",
    mt: 6,
    pl: { xs: 0, md: 8 },
  },
  button: {
    width: 200,
    borderRadius: 4,
    fontSize: 20,
    fontFamily: "'Bebas Neue', sans-serif",
    background: colorPalette.headerBackground,
    "&:hover": {
      backgroundColor: colorPalette.sectionTitle,
      opacity: 0.7,
    },
  },
  imageGrid: {
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
    mb: 1,
  },
  image: {
    borderRadius: 8,
    width: 350,
  },
};
