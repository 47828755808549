import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { customStyles } from "./index.styles";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import FactoryIcon from "@mui/icons-material/Factory";
import ScienceIcon from "@mui/icons-material/Science";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import { colorPalette } from "../../../../shared/utils/constants";

const imageURL = `${process.env.PUBLIC_URL}/images/whyUs.png`;

export const WhyUsSection = () => {
  return (
    <Box sx={{ background: colorPalette.sectionTitle }}>
      <Container maxWidth="xl" sx={{ py: 4 }}>
        <Grid container direction="row" alignItems="center" spacing={4}>
          <Grid xs={12} md={6} item>
            <Box mt={6} pl={{ xs: 0, md: 8 }}>
              <Typography variant="h3" sx={customStyles.text}>
                Why go with Radiant Property Management?
              </Typography>
            </Box>

            <Typography sx={customStyles.description}>
              Our unwavering dedication to exceptional service is reflected in
              our 100% customer satisfaction rate. Our clients consistently
              trust us to meet their needs, showcasing our commitment to
              excellence and fostering lasting relationships built on
              reliability and quality cleaning solutions
            </Typography>

            <Grid container direction="row" mt={2} pl={{ xs: 0, md: 8 }}>
              <Grid item>
                <Stack spacing={2}>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <AutoAwesomeIcon sx={customStyles.icon} />

                    <Typography sx={customStyles.cardHeading}>
                      Over 7 Years of Varied Expertise
                    </Typography>
                  </Stack>

                  <Stack spacing={2} direction="row" alignItems="center">
                    <TipsAndUpdatesIcon sx={customStyles.icon} />

                    <Typography sx={customStyles.cardHeading}>
                      Skilled, Screened & Fully Insured Teams
                    </Typography>
                  </Stack>

                  <Stack spacing={2} direction="row" alignItems="center">
                    <Diversity1Icon sx={customStyles.icon} />

                    <Typography sx={customStyles.cardHeading}>
                      Eco-Friendly Practices
                    </Typography>
                  </Stack>

                  <Stack spacing={2} direction="row" alignItems="center">
                    <FactoryIcon sx={customStyles.icon} />

                    <Typography sx={customStyles.cardHeading}>
                      Industry Partnerships & Expertise
                    </Typography>
                  </Stack>

                  <Stack spacing={2} direction="row" alignItems="center">
                    <ScienceIcon sx={customStyles.icon} />

                    <Typography sx={customStyles.cardHeading}>
                      Commitment to Quality & Innovation
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>

          <Grid xs={12} md={6} item>
            <Box
              sx={[
                customStyles.mainBox,
                { backgroundImage: `url(${imageURL})` },
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
