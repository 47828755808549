import { Box, Typography } from "@mui/material";
import { customStyles } from "./index.styles";
import { colorPalette } from "../../utils/constants";
import { Link } from "react-router-dom";

const Footer = () => (
  <Box sx={customStyles.mainBox}>
    <Box sx={customStyles.innerBox}>
      {/* <img
        alt="logo"
        width={120}
        style={customStyles.image}
        src={`${process.env.PUBLIC_URL}/images/logo.png`}
      /> */}

      <Typography sx={customStyles.title}>
        Radiant Property Management
      </Typography>
    </Box>

    <Box display="flex" flexDirection="column" textAlign="center">
      <Box
        sx={customStyles.numberButton}
        onClick={() => (window.location.href = `tel:${+61466469104}`)}
      >
        <Typography mt={0.5} color={colorPalette.white}>
          {`Contact number: +61 466 469 104`}
        </Typography>
      </Box>

      <Link
        to="mailto:info@radiantpm.com.au"
        target="_blank"
        rel="noopener noreferrer"
        style={customStyles.link}
      >
        <Typography mt={0.5} color={colorPalette.white}>
          {`  Email: info@radiantpm.com.au`}
        </Typography>
      </Link>
    </Box>

    <Box>
      <Typography mt={0.5} color={colorPalette.white}>
        All Rights Reserved
      </Typography>
    </Box>
  </Box>
);

export default Footer;
