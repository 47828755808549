import { Box } from "@mui/material";
import { customStyles } from "./index.styles";
import HomeSectionText from "./HomeSectionText";
import { WhyUsSection } from "./WhyUsSection";

interface Props {
  id: string;
}

const headerImage = `${process.env.PUBLIC_URL}/images/header2.png`;
const headerImageSmall = `${process.env.PUBLIC_URL}/images/header3.png`;

const HomeSection = ({ id }: Props) => (
  <Box id={id}>
    <Box
      sx={[
        customStyles.mainBox,
        {
          backgroundImage: {
            xs: `url(${headerImageSmall})`,
            md: `url(${headerImage})`,
          },
        },
      ]}
    />

    <Box
      sx={{
        top: 0,
        height: "100vh",
        background: "white",
        opacity: 0.4,
        width: "99.5vw",
        position: "absolute",
      }}
    />

    <HomeSectionText />

    <WhyUsSection />
  </Box>
);

export default HomeSection;
