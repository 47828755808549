import { colorPalette } from "../../../../shared/utils/constants";

export const customStyles = {
  text: {
    width: "90%",
    display: "flex",
    fontFamily: "'Bebas Neue', sans-serif",
    // justifyContent: "center",
    flexWrap: "wrap",
    color: colorPalette.white,
  },
  card: {
    maxWidth: 400,
    boxShadow: 5,
  },
  cardHeading: {
    color: colorPalette.white,
    fontSize: { xs: 18, md: 24 },
  },
  icon: {
    color: colorPalette.white,
    alignItems: "center",
    fontSize: { xs: 28, md: 42 },
  },
  description: {
    my: 4,
    pl: { xs: 0, md: 8 },
    color: colorPalette.white,
    fontSize: 18,
    display: "flex",
    flexWrap: "wrap",
    wordBreak: "break-word",
  },
  mainBox: {
    mt: { xs: -10, md: 0 },
    ml: { xs: 8, md: 8 },
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: 700,
  },
};
