import { colorPalette } from "../../../shared/utils/constants";

export const customStyles = {
  card: {
    display: {
      xs: "none",
      md: "block",
    },
    px: { xs: 0, md: 8 },
  },
  text: {
    width: "90%",
    display: "flex",
    fontFamily: "'Bebas Neue', sans-serif",
    justifyContent: "center",
    flexWrap: "wrap",
    color: colorPalette.white,
  },
  cardMedia: {
    width: "30vw",
  },
  cardContent: {
    display: "flex",
    alignItems: "center",
  },
  textCard: {
    borderRadius: 4,
    display: {
      xs: "block",
      md: "none",
    },
  },
  icon: {
    fontSize: {
      xs: 20,
      sm: 28,
    },
    color: colorPalette.white,
  },
  iconText: {
    display: "flex",
    fontFamily: "'Bebas Neue', sans-serif",
    justifyContent: "center",
    textAlign: "center",
    color: colorPalette.white,
  },
  iconGrid: {
    display: "flex",
    justifyContent: "center",
  },
  iconBox: {
    mt: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 2,
  },
  mainBox: {
    ml: { xs: 2, md: 8 },
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: { xs: 450, sm: 600 },
  },
};
