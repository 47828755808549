export const customStyles = {
  mainBox: {
    opacity: 0.6,
    height: "100vh",
    background:
      "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(9,9,121,0) 35%)", // Left to right gradient
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
  },
};
